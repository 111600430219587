body {
  font-family: 'Lato', sans-serif;
  font-size: 1em;
  line-height: 1.3;
  background-color: #f6f7f9;
  color: #404e5f;
  margin: 0;
  padding: 0; }

a {
  text-decoration: none; }

input, textarea {
  font-family: 'Lato', sans-serif;
  background-color: transparent; }
